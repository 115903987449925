import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import footerDeSenhasStyles from './style'

const FooterPaineDeSenhas = () => {
  const styles = footerDeSenhasStyles()

  return (
    <Box className={styles.main}>
      <Typography variant="button" className={styles.title}>
        Seja Bem-vindo! Retire sua senha e aguarde ser chamado
      </Typography>
    </Box>
  )
}

export default FooterPaineDeSenhas
