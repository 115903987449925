import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import React from 'react'

import { extrairGuiche } from '../../../../../../../utils/extrairGuiche'
import { extrairSenha } from '../../../../../../../utils/extrairSenha'
import ClockIcon from '../../../../../Icons/Clock'
import PersonIcon from '../../../../../Icons/Person'
import TicketIcon from '../../../../../Icons/Ticket'
import PausePanel from '../../PausePanel'
import PainelSemSenha from '../SemSenha/index'
import painelComSenhaStyles from './style'
import { Props } from './types/index'

const PainelComSenha = ({
  SenhaAtual,
  SenhasChamadas,
  Noticias,
  MostrarNoticias,
}: Props) => {
  const styles = painelComSenhaStyles()
  const isHd = useMediaQuery('(min-width:1280px)')
  const isFullHd = useMediaQuery('(min-width:1920px)')

  return (
    <Box className={styles.main}>
      <Box className={styles.containerEsquerdo}>
        {SenhaAtual ? (
          <Box
            className={styles.esquerdoTop}
            style={{
              backgroundImage:
                SenhaAtual.payload.prioridade &&
                SenhaAtual.payload.prioridade_alta
                  ? 'url(/assets/images/city-vermelho.webp)'
                  : SenhaAtual.payload.prioridade
                  ? 'url(/assets/images/city-azul.webp)'
                  : 'url(/assets/images/city-verde.webp)',
            }}
          >
            <Box
              className={styles.titleContainer}
              style={{
                backgroundColor:
                  SenhaAtual.payload.prioridade &&
                  SenhaAtual.payload.prioridade_alta
                    ? '#DC2626'
                    : SenhaAtual.payload.prioridade
                    ? '#2563EB'
                    : '#059669',
              }}
            >
              <Typography
                variant="button"
                className={isFullHd ? styles.title : styles.titleSmall}
              >
                <TicketIcon />
                Senha chamada
              </Typography>
            </Box>
            <Box className={styles.esqurdoConteudoSenha}>
              <Box className={styles.esqurdoSenha}>
                <Typography
                  variant="h6"
                  className={styles.titleSenha}
                  style={{
                    color:
                      SenhaAtual.payload.prioridade &&
                      SenhaAtual.payload.prioridade_alta
                        ? '#7F1D1D'
                        : SenhaAtual.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                  }}
                >
                  {extrairSenha(SenhaAtual.payload.senha)}
                </Typography>
                <FiberManualRecordIcon
                  className={styles.titleFiberSenha}
                  style={{
                    color:
                      SenhaAtual.payload.prioridade &&
                      SenhaAtual.payload.prioridade_alta
                        ? '#7F1D1D'
                        : SenhaAtual.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                  }}
                />
                <Typography
                  variant="h6"
                  className={styles.titleSenha}
                  style={{
                    color:
                      SenhaAtual.payload.prioridade &&
                      SenhaAtual.payload.prioridade_alta
                        ? '#7F1D1D'
                        : SenhaAtual.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                  }}
                >
                  {extrairGuiche(SenhaAtual.payload.guiche)}
                </Typography>
              </Box>
              <Box className={styles.esquerdoUser}>
                <Typography
                  variant="body1"
                  className={styles.titleUser}
                  style={{
                    textAlign: 'center',
                    color:
                      SenhaAtual.payload.prioridade &&
                      SenhaAtual.payload.prioridade_alta
                        ? '#7F1D1D'
                        : SenhaAtual.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                  }}
                >
                  <PersonIcon
                    styles={{
                      width: '42px',
                      height: '42px',
                      color:
                        SenhaAtual.payload.prioridade &&
                        SenhaAtual.payload.prioridade_alta
                          ? '#7F1D1D'
                          : SenhaAtual.payload.prioridade
                          ? '#1E3A8A'
                          : '#064E3B',
                    }}
                  />{' '}
                  {SenhaAtual.payload.nome}
                </Typography>
              </Box>
              <Typography
                variant="h4"
                style={{
                  color:
                    SenhaAtual.payload.prioridade &&
                    SenhaAtual.payload.prioridade_alta
                      ? '#7F1D1D'
                      : SenhaAtual.payload.prioridade
                      ? '#1E3A8A'
                      : '#064E3B',
                }}
              >
                {SenhaAtual.payload.servico_titulo}
              </Typography>
              <Box
                className={styles.prioridade}
                style={{
                  padding: '2px 12px 2px 8px',
                  borderRadius: '50px',
                  border: `2px solid ${
                    SenhaAtual.payload.prioridade &&
                    SenhaAtual.payload.prioridade_alta
                      ? '#7F1D1D'
                      : SenhaAtual.payload.prioridade
                      ? '#1E3A8A'
                      : '#064E3B'
                  }`,
                }}
              >
                <FiberManualRecordIcon
                  className={styles.FiberTipoSenha}
                  style={{
                    color:
                      SenhaAtual.payload.prioridade &&
                      SenhaAtual.payload.prioridade_alta
                        ? '#7F1D1D'
                        : SenhaAtual.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                  }}
                />
                <Typography
                  variant="h4"
                  style={{
                    color:
                      SenhaAtual.payload.prioridade &&
                      SenhaAtual.payload.prioridade_alta
                        ? '#7F1D1D'
                        : SenhaAtual.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                  }}
                >
                  {SenhaAtual.payload.prioridade &&
                  SenhaAtual.payload.prioridade_alta
                    ? 'Prioridade Alta'
                    : SenhaAtual.payload.prioridade
                    ? 'Prioridade'
                    : 'Normal'}
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : Noticias.length === 0 || !MostrarNoticias ? (
          <Box className={styles.esquerdoTop}>
            <Box
              className={styles.titleContainer}
              style={{
                backgroundColor: '#4B5563',
              }}
            >
              <Typography
                variant="button"
                className={isFullHd ? styles.title : styles.titleSmall}
              >
                <TicketIcon /> Senha Chamada
              </Typography>
            </Box>
            <PainelSemSenha />
          </Box>
        ) : (
          Noticias.length > 0 &&
          MostrarNoticias && (
            <Box className={styles.esquerdoTop}>
              <PausePanel newsResponse={Noticias} />
            </Box>
          )
        )}

        <Box className={styles.esquerdoBotton}>
          {SenhasChamadas.slice(0, 3).map((senha, index) => (
            <Box
              key={index}
              className={styles.esquerdoBottonItem}
              style={{
                backgroundImage:
                  senha.payload.prioridade && senha.payload.prioridade_alta
                    ? 'url(/assets/images/city-vermelho.webp)'
                    : senha.payload.prioridade_alta
                    ? 'url(/assets/images/city-azul.webp)'
                    : 'url(/assets/images/city-verde.webp)',
              }}
            >
              <Box
                className={styles.titleContainer}
                style={{
                  backgroundColor:
                    senha.payload.prioridade && senha.payload.prioridade_alta
                      ? '#DC2626'
                      : senha.payload.prioridade
                      ? '#2563EB'
                      : '#059669',
                }}
              >
                <Typography
                  variant="button"
                  className={
                    isFullHd
                      ? styles.title
                      : !isHd
                      ? styles.titleHd
                      : styles.titleSmall
                  }
                >
                  <TicketIcon />
                  {index === 0 && ' Última Chamada'}
                  {index === 1 && ' Penúltima Chamada'}
                  {index === 2 && ' Antepenúltima Chamada'}
                </Typography>
              </Box>
              <Box className={styles.esqurdoConteudoSenha}>
                <Typography
                  variant="h4"
                  className={
                    isFullHd
                      ? styles.titleSenhaBottonItem
                      : !isHd
                      ? styles.titleSenhaBottonItemSmallHd
                      : styles.titleSenhaBottonItemSmall
                  }
                  style={{
                    color:
                      senha.payload.prioridade && senha.payload.prioridade_alta
                        ? '#7F1D1D'
                        : senha.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                  }}
                >
                  {extrairSenha(senha.payload.senha)}{' '}
                  <FiberManualRecordIcon
                    className={styles.titleFiberSenha}
                    style={{
                      color:
                        senha.payload.prioridade &&
                        senha.payload.prioridade_alta
                          ? '#7F1D1D'
                          : senha.payload.prioridade
                          ? '#1E3A8A'
                          : '#064E3B',
                    }}
                  />{' '}
                  {extrairGuiche(senha.payload.guiche)}
                </Typography>
                <Typography
                  variant="body1"
                  className={
                    isFullHd
                      ? styles.titleUserBottomIcon
                      : !isHd
                      ? styles.titleUserBottomIconSmallHd
                      : styles.titleUserBottomIconSmall
                  }
                  style={{
                    color:
                      senha.payload.prioridade && senha.payload.prioridade_alta
                        ? '#7F1D1D'
                        : senha.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                  }}
                >
                  <PersonIcon
                    styles={{
                      width: isFullHd ? '22px' : '17px',
                      height: isFullHd ? '22px' : '17px',
                      color:
                        senha.payload.prioridade &&
                        senha.payload.prioridade_alta
                          ? '#7F1D1D'
                          : senha.payload.prioridade
                          ? '#1E3A8A'
                          : '#064E3B',
                    }}
                  />{' '}
                  {senha.payload.nome}
                </Typography>
                <Typography
                  variant="h4"
                  style={{
                    color:
                      senha.payload.prioridade && senha.payload.prioridade_alta
                        ? '#7F1D1D'
                        : senha.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B',
                    fontSize: isFullHd ? '26px' : !isHd ? '15px' : '18px',
                  }}
                >
                  {senha.payload.servico_titulo}
                </Typography>
                <Box
                  className={styles.prioridade}
                  style={{
                    padding: '2px 12px 2px 8px',
                    borderRadius: '50px',
                    border: `2px solid ${
                      senha.payload.prioridade && senha.payload.prioridade_alta
                        ? '#7F1D1D'
                        : senha.payload.prioridade
                        ? '#1E3A8A'
                        : '#064E3B'
                    }`,
                  }}
                >
                  <FiberManualRecordIcon
                    className={styles.FiberTipoSenha}
                    style={{
                      color:
                        senha.payload.prioridade &&
                        senha.payload.prioridade_alta
                          ? '#7F1D1D'
                          : senha.payload.prioridade
                          ? '#1E3A8A'
                          : '#064E3B',
                    }}
                  />
                  <Typography
                    variant="h4"
                    style={{
                      color:
                        senha.payload.prioridade &&
                        senha.payload.prioridade_alta
                          ? '#7F1D1D'
                          : senha.payload.prioridade
                          ? '#1E3A8A'
                          : '#064E3B',
                      fontSize: isFullHd ? '25px' : !isHd ? '10px' : '15px',
                    }}
                  >
                    {senha.payload.prioridade && senha.payload.prioridade_alta
                      ? 'Prioridade Alta'
                      : senha.payload.prioridade
                      ? 'Prioridade'
                      : 'Normal'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}

          {!SenhasChamadas[0] && (
            <Box className={styles.esquerdoBottonItem}>
              <Box
                className={styles.titleContainer}
                style={{
                  backgroundColor: '#4B5563',
                }}
              >
                <Typography
                  variant="button"
                  className={isFullHd ? styles.title : styles.titleSmall}
                >
                  <TicketIcon /> Última Chamada
                </Typography>
              </Box>
              <Box className={styles.esqurdoConteudoSenha}>
                <Box className={styles.containerSemSenha}>
                  <ClockIcon
                    styles={{
                      width: '70px',
                      height: '70px',
                      fontSize: '70px',
                      color: '#9CA3AF',
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{
                      color: '#6B7280',
                      fontWeight: 700,
                    }}
                  >
                    Aguardando chamada anterior.
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {!SenhasChamadas[1] && (
            <Box className={styles.esquerdoBottonItem}>
              <Box
                className={styles.titleContainer}
                style={{
                  backgroundColor: '#4B5563',
                }}
              >
                <Typography
                  variant="button"
                  className={isFullHd ? styles.title : styles.titleSmall}
                >
                  <TicketIcon /> Penúltima Chamada
                </Typography>
              </Box>
              <Box className={styles.esqurdoConteudoSenha}>
                <Box className={styles.containerSemSenha}>
                  <ClockIcon
                    styles={{
                      width: '70px',
                      height: '70px',
                      fontSize: '70px',
                      color: '#9CA3AF',
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{
                      color: '#6B7280',
                      fontWeight: 700,
                    }}
                  >
                    Aguardando chamada anterior.
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {!SenhasChamadas[2] && (
            <Box className={styles.esquerdoBottonItem}>
              <Box
                className={styles.titleContainer}
                style={{
                  backgroundColor: '#4B5563',
                }}
              >
                <Typography
                  variant="button"
                  className={isFullHd ? styles.title : styles.titleSmall}
                >
                  <TicketIcon /> Antepenúltima Chamada
                </Typography>
              </Box>
              <Box className={styles.esqurdoConteudoSenha}>
                <Box className={styles.containerSemSenha}>
                  <ClockIcon
                    styles={{
                      width: '70px',
                      height: '70px',
                      fontSize: '70px',
                      color: '#9CA3AF',
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{
                      color: '#6B7280',
                      fontWeight: 700,
                    }}
                  >
                    Aguardando chamada anterior.
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Box className={styles.containerDireito}>
        <Box
          className={styles.titleContainerDireito}
          style={{
            backgroundColor: '#4B5563',
          }}
        >
          <Typography
            variant="button"
            className={isFullHd ? styles.title : styles.titleSmall}
          >
            <TicketIcon /> Senhas Anteriores
          </Typography>
        </Box>

        {SenhasChamadas.length > 0 && (
          <Box className={styles.senhasChamadasLista}>
            {SenhasChamadas &&
              SenhasChamadas.length > 0 &&
              SenhasChamadas.slice(3, isHd ? 11 : 12).map((senha, index) => (
                <Box key={index} className={styles.direitoItem}>
                  <Box className={styles.direitoConteudoSenha}>
                    <Typography
                      variant="h4"
                      className={
                        isFullHd
                          ? styles.titleSenhaBottonItem
                          : styles.titleSenhaBottonItemSmall
                      }
                    >
                      {extrairSenha(senha.payload.senha)}{' '}
                      <FiberManualRecordIcon
                        className={styles.titleFiberSenha}
                      />{' '}
                      {extrairGuiche(senha.payload.guiche)}
                    </Typography>
                  </Box>
                  <Box className={styles.direitoUser}>
                    <Typography>
                      <PersonIcon
                        styles={{
                          height: '16px',
                        }}
                      />{' '}
                      {senha.payload.nome}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
        )}

        {SenhasChamadas.length == 0 && (
          <Box
            className={styles.direitoSemItem}
            style={{
              backgroundColor: '#FFFFFF',
            }}
          >
            <Box className={styles.direitoConteudoSemSenha}>
              <Box className={styles.containerSemSenha}>
                <ClockIcon
                  styles={{
                    width: '70px',
                    height: '70px',
                    fontSize: '70px',
                    color: '#9CA3AF',
                  }}
                />
                <Typography
                  variant="h6"
                  style={{
                    color: '#6B7280',
                    fontWeight: 700,
                  }}
                >
                  Nenhuma senha chamada recentemente.
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default PainelComSenha
