import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import LinearProgress from '@material-ui/core/LinearProgress'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import React, { Fragment, useEffect } from 'react'

import BankIcon from '../../Icons/Bank'
import CalendaryIcon from '../../Icons/Calendary'
import TemperatureIcon from '../../Icons/Temperature'
import headerPainelDeSenhasStyles from './style'
import { ItensMenuProps, Props } from './types/index'
import { useHeaderPainelDeSenhas } from './useHeaderPainelDeSenhas'

const HeaderPainelDeSenhas = ({
  BuscandoGuiche,
  GuichesDisponiveis,
  onSelectGuiche,
}: Props) => {
  const styles = headerPainelDeSenhasStyles()
  const isFullHd = useMediaQuery('(min-width:1920px)')

  const {
    clima,
    horaAtual,
    abrirSelect,
    returnWeekDay,
    buscandoClima,
    handleGetClima,
    setAbrirSelect,
    handleGetGuiche,
    guicheSelecionado,
    returnCurrentTime,
    handleValueOptions,
    handleSelectGuiche,
    guichesFiltrados,
  } = useHeaderPainelDeSenhas({
    BuscandoGuiche,
    GuichesDisponiveis,
    onSelectGuiche,
  })

  useEffect(() => {
    handleGetGuiche()
    handleGetClima()
  }, [])

  useEffect(() => {
    handleGetClima()
  }, [guicheSelecionado])

  useEffect(() => {
    const interval = setInterval(() => {
      returnCurrentTime()
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      handleGetClima()
    }, 3600000) // 1 hora

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (guicheSelecionado) {
      onSelectGuiche(guicheSelecionado)
      const guicheLocal = localStorage.getItem('CurrentSlug')
      if (
        guicheLocal !== guicheSelecionado.setor?.unidade.slug_unidade &&
        guicheLocal !== guicheSelecionado.unidade?.slug_unidade
      ) {
        localStorage.setItem(
          'CurrentSlug',
          guicheSelecionado.setor
            ? guicheSelecionado.setor.unidade.slug_unidade
            : guicheSelecionado.unidade
            ? guicheSelecionado.unidade.slug_unidade
            : '',
        )
        localStorage.removeItem('SenhaAtual')
        localStorage.removeItem('FilaDeSenhas')
        localStorage.removeItem('SenhasChamadas')
        localStorage.removeItem('StorageDate')
      }
    }
  }, [guicheSelecionado])

  const ItensMenu: ItensMenuProps[] = [
    {
      icone: (
        <BankIcon
          styles={{ width: isFullHd ? '30px' : '20px', color: '#6B7280' }}
        />
      ),
      titulo: 'Local de atendimento',
    },
    {
      icone: (
        <TemperatureIcon
          styles={{ width: isFullHd ? '20px' : '10px', color: '#6B7280' }}
        />
      ),
      titulo: 'Temperatura',
    },
    {
      icone: (
        <CalendaryIcon
          styles={{ width: isFullHd ? '30px' : '20px', color: '#6B7280' }}
        />
      ),
      titulo: returnWeekDay(),
    },
  ]

  return (
    <Box className={styles.main}>
      <img
        src="https://uploaddeimagens.com.br/images/003/998/232/original/salvador_logo.png"
        alt="Logo Salvador Digital"
        height="100%"
      />
      <Box className={styles.containerItemMenu}>
        {ItensMenu.map((item, index) => (
          <Box key={index} className={styles.itemMenu}>
            <Divider orientation="vertical" />
            {item.icone}
            {index === 0 ? (
              <Box className={styles.containerSelect}>
                <Box className={styles.containerIni}>
                  <Typography
                    variant="h1"
                    className={
                      isFullHd
                        ? styles.tituloItemMenu
                        : styles.tituloItemMenuSmall
                    }
                  >
                    {item.titulo}
                  </Typography>
                  {BuscandoGuiche ? (
                    <LinearProgress style={{ width: '100%' }} />
                  ) : (
                    <Fragment>
                      {abrirSelect ? (
                        <Select
                          open={abrirSelect}
                          variant="standard"
                          onClose={() => setAbrirSelect(!abrirSelect)}
                          onChange={(e) =>
                            handleSelectGuiche(Number(e.target.value))
                          }
                        >
                          {guichesFiltrados.map((guiche) => (
                            <option
                              value={guiche.id}
                              key={guiche.id}
                              className={styles.opcao}
                            >
                              {handleValueOptions(guiche)}
                            </option>
                          ))}
                        </Select>
                      ) : (
                        <Typography
                          variant="h2"
                          className={
                            isFullHd
                              ? styles.subTituloItemMenu
                              : styles.subTituloItemMenuSmall
                          }
                        >
                          {guicheSelecionado
                            ? handleValueOptions(guicheSelecionado)
                            : 'Nenhum guichê selecionado'}
                        </Typography>
                      )}
                    </Fragment>
                  )}
                </Box>
                <KeyboardArrowDownIcon
                  className={styles.iconeSeta}
                  onClick={() => setAbrirSelect(!abrirSelect)}
                />
              </Box>
            ) : index === 1 ? (
              <Box className={styles.containerIni}>
                <Typography
                  variant="h1"
                  className={
                    isFullHd
                      ? styles.tituloItemMenu
                      : styles.tituloItemMenuSmall
                  }
                >
                  {item.titulo}
                </Typography>
                {buscandoClima && !clima ? (
                  <LinearProgress style={{ width: '100%' }} />
                ) : (
                  <Typography
                    variant="h2"
                    className={
                      isFullHd
                        ? styles.subTituloItemMenu
                        : styles.subTituloItemMenuSmall
                    }
                  >
                    {clima ? `${clima.toFixed()}°C` : 'Sem informações'}
                  </Typography>
                )}
              </Box>
            ) : (
              index === 2 && (
                <Box className={styles.containerIni}>
                  <Typography
                    variant="h1"
                    className={
                      isFullHd
                        ? styles.tituloItemMenu
                        : styles.tituloItemMenuSmall
                    }
                  >
                    {item.titulo}
                  </Typography>
                  <Typography
                    variant="h2"
                    className={
                      isFullHd
                        ? styles.subTituloItemMenu
                        : styles.subTituloItemMenuSmall
                    }
                  >
                    {horaAtual}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default HeaderPainelDeSenhas
