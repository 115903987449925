import { WebSocketResponse } from '../Pages/PainelDeSenhas/types/ws'
import { extrairGuiche } from './extrairGuiche'
import { extrairSenha } from './extrairSenha'

export function speakText(
  schedule: WebSocketResponse,
  rate = 1,
  pitch = 1,
  volume = 1,
  voiceName = 'Google português do Brasil',
): void {
  if ('speechSynthesis' in window) {
    const text = `${schedule.payload.nome}. Senha: ${extrairSenha(
      schedule.payload.senha,
    )}. ${extrairGuiche(schedule.payload.guiche)}`
    console.log(text)

    const utterance = new SpeechSynthesisUtterance(text)
    utterance.rate = rate
    utterance.pitch = pitch
    utterance.volume = volume

    const speak = () => {
      const voices = window.speechSynthesis.getVoices()
      let selectedVoice

      if (voiceName) {
        selectedVoice = voices.find((voice) => voice.name === voiceName)
        if (!selectedVoice) {
          console.warn(`Voz "${voiceName}" não encontrada. Usando voz padrão.`)
        }
      }

      if (!selectedVoice) {
        selectedVoice = voices.find(
          (voice) => voice.name.includes('Google') && voice.lang === 'pt-BR',
        )
        if (!selectedVoice) {
          console.warn(
            'Voz do Google em português do Brasil não encontrada. Usando voz padrão do sistema.',
          )
        }
      }

      if (selectedVoice) {
        utterance.voice = selectedVoice
      }

      window.speechSynthesis.speak(utterance)
    }

    const waitForVoices = () => {
      return new Promise<void>((resolve) => {
        const voices = window.speechSynthesis.getVoices()
        if (voices.length !== 0) {
          resolve()
        } else {
          window.speechSynthesis.onvoiceschanged = () => {
            resolve()
          }
        }
      })
    }

    waitForVoices()
      .then(speak)
      .catch((error) => {
        console.error('Erro ao carregar vozes:', error)
      })
  } else {
    console.error('API Web Speech não é suportada neste navegador.')
  }
}
