import { makeStyles } from '@material-ui/core/styles'

import theme from '../../../../providers/theme-service'

export const headerPainelDeSenhasStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    maxHeight: '10vh',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerItemMenu: {
    padding: '0px 10px 0px 0px',
    gap: '1.5rem',
    display: 'flex',
    height: '70%',
  },
  itemMenu: {
    gap: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  tituloItemMenu: {
    fontSize: '1.2rem',
    color: '#6B7280',
  },
  tituloItemMenuSmall: {
    fontSize: '.8rem',
    color: '#6B7280',
  },
  subTituloItemMenu: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  subTituloItemMenuSmall: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  containerSelect: {
    gap: '1rem',
    display: 'flex',
    alignItems: 'center',
    minWidth: '300px',
  },
  iconeSeta: {
    color: '#6B7280',
  },
  containerIni: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%',
  },
  opcao: {
    cursor: 'pointer',
    padding: '0.3rem',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
}))

export default headerPainelDeSenhasStyles
