import { makeStyles } from '@material-ui/core/styles'

export const bodyPainelDeSenhasStyles = makeStyles(() => ({
  main: {
    width: '100%',
    height: '100%',
    maxHeight: '87vh',
  },
}))

export default bodyPainelDeSenhasStyles
