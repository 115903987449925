import { makeStyles } from '@material-ui/core/styles'

export const footerDeSenhasStyles = makeStyles(() => ({
  main: {
    width: '100%',
    height: '100%',
    maxHeight: '3vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#1F2937',
    zIndex: 999,
  },
  title: {
    color: 'white',
    fontSize: '16px',
  },
}))

export default footerDeSenhasStyles
