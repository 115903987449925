import { makeStyles } from '@material-ui/core/styles'

export const painelComSenhaStyles = makeStyles(() => ({
  main: {
    gap: '.1rem',
    width: '100%',
    height: '100%',
    display: 'flex',
    textAlign: 'center',
  },
  containerEsquerdo: {
    width: '70%',
    height: '100%',
  },
  containerDireito: {
    width: '30%',
    height: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    flexDirection: 'column',
    backgroundColor: '#F3F4F6',
  },
  senhasChamadasLista: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    justifyContent: 'start',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  },
  '::-webkit-scrollbar': {
    display: 'none',
  },
  esquerdoTop: {
    width: '100%',
    height: '70%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundSize: 'cover',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  titleContainer: {
    gap: '10px',
    width: '100%',
    color: 'white',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: 900,
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  titleSmall: {
    fontSize: '19px',
    fontWeight: 900,
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  titleHd: {
    fontSize: '10px',
    fontWeight: 900,
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  esqurdoConteudoSenha: {
    gap: '5px',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'start',
  },
  direitoConteudoSemSenha: {
    gap: '5px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  direitoConteudoSenha: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  esqurdoSenha: {
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  direitoSenha: {
    gap: '5px',
    width: '60%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  direitoSenhaSmall: {
    gap: '5px',
    width: '60%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  titleFiberSenha: {
    width: '5px',
    height: '10px',
    fontSize: '24px',
  },
  FiberTipoSenha: {
    width: '9px',
    height: '16px',
    fontSize: '24px',
  },
  titleSenha: {
    fontSize: '76px',
  },
  esquerdoUser: {
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  direitoUser: {
    gap: '10px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  titleUser: {
    fontSize: '56px',
  },
  prioridade: {
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  esquerdoBotton: {
    gap: '.1rem',
    height: '30%',
    display: 'flex',
    flexDirection: 'row',
  },
  esquerdoBottonItem: {
    width: '100%',
    height: '100%',
  },
  direitoSemItem: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  direitoItem: {
    width: '100%',
    height: '126px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#F9FAFB',
    marginBottom: '2px',
  },
  titleSenhaBottonItem: {
    fontSize: '30px',
  },
  titleSenhaBottonItemSmall: {
    fontSize: '23px',
  },
  titleSenhaBottonItemSmallHd: {
    fontSize: '15px',
  },
  titleUserBottomIcon: {
    fontSize: '20px',
  },
  titleUserBottomIconSmall: {
    fontSize: '15px',
  },
  titleUserBottomIconSmallHd: {
    fontSize: '10px',
  },
  titleUserIcon: {
    width: '100%',
    maxWidth: '40%',
    fontSize: '20px',
    textAlign: 'start',
  },
  titleUserIconSmall: {
    width: '100%',
    maxWidth: '100%',
    fontSize: '20px',
    textAlign: 'start',
  },
  containerSemSenha: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  titleContainerDireito: {
    width: '100%',
    color: 'white',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0',
  },
  lastPass: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
}))

export default painelComSenhaStyles
